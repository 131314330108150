<template>
  <div class="content_body entityConfig">
    <div class="nav_header">
      <el-form :inline="true" :model="modelForm" size="small">
        <el-row>
          <el-col :span="22">
            <el-form-item label="门店">
              <el-select
                v-model="modelForm.EntityID"
                placeholder="请选择"
                clearable
                @change="handlerChange"
                @clear="handlerChange"
              >
                <el-option
                  v-for="item in EntityList"
                  :key="item.ID"
                  :label="item.EntityName"
                  :value="item.ID"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="到期时间筛选">
              <el-date-picker
                v-model="modelForm.QueryData"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                clearable
                @change="handlerChange"
                @clear="handlerChange"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="handlerChange"
                >搜索</el-button
              >
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-button type="primary" size="small" @click="entityConfigClick"
              >新增</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </div>

    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="EntityName" label="门店名"> </el-table-column>
      <el-table-column prop="ExpireDate" label="到期时间"> </el-table-column>
      <el-table-column prop="Remark" label="备注"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="small"
            @click="entityConfigBian(scope.row)"
            >编辑</el-button
          >
          <el-button
            type="danger"
            size="small"
            @click="entityConfigDelete(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pad_15 text_right">
      <el-pagination
        background
        v-if="paginations.total > 0"
        @current-change="handleCurrentChange"
        :current-page.sync="paginations.page"
        :page-size="paginations.page_size"
        :layout="paginations.layout"
        :total="paginations.total"
      ></el-pagination>
    </div>

    <!-- 新增和编辑弹框 -->
    <el-dialog :title="title" :visible.sync="dialogVisible" width="35%">
      <el-form
        :model="modelFormDialog"
        :inline-message="true"
        size="small"
        :rules="rules"
        ref="ref"
      >
        <el-form-item label="门店" prop="EntityTwoID">
          <el-select
            v-model="modelFormDialog.EntityTwoID"
            placeholder="请选择"
            clearable
            :disabled="title == '编辑'"
          >
            <el-option
              v-for="item in EntityList"
              :key="item.ID"
              :label="item.EntityName"
              :value="item.ID"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="到期时间" prop="QueryDataTwo">
          <el-date-picker
            v-model="modelFormDialog.QueryDataTwo"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            clearable
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="modelFormDialog.Remark"
            style="width: 70%"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="dialogEntityConfig" size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 删除弹框 -->
    <el-dialog title="删除" :visible.sync="dialogVisibleDelete" width="20%">
      <span>确定删除吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleDelete = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="dialogEntityConfigDelete" size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import APIStorage from "@/api/PSI/Purchase/storage";
import API from "@/api/KHS/Entity/entityConfig";

export default {
  data() {
    return {
      modelForm: {
        EntityID: "",
        QueryData: [],
      },
      modelFormDialog: {
        EntityTwoID: "",
        QueryDataTwo: "",
        Remark: "",
      },
      tableData: [], // 列表
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      dialogVisible: false, // 新增和编辑弹框
      dialogVisibleDelete:false, // 删除弹框
      title: "", // 弹框
      rules: {
        EntityTwoID: [{ required: true, message: "请选择", trigger: "blur" }],
        QueryDataTwo: [{ required: true, message: "请选择", trigger: "blur" }],
      },
      EntityList: [], //门店
      rowDelete:'',
    };
  },

  methods: {
    /**  仓库列表  */
    getStorageEntityNetwork: function () {
      var that = this;
      var params = {};
      APIStorage.getpurchaseStorageEntity(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.EntityList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },
    // 触发搜索
    handlerChange() {
      this.paginations.page = 1;
      this.searchEntity();
    },
    // 搜索
    searchEntity() {
      var that = this;
      var params = {
        PageNum: that.paginations.page,
        StartDate: that.modelForm.QueryData ? that.modelForm.QueryData[0] : "",
        EndDate: that.modelForm.QueryData ? that.modelForm.QueryData[1] : "",
        EntityID: that.modelForm.EntityID,
      };
      API.getEntityConfigList(params).then((res) => {
        if (res.StateCode == 200) {
          that.tableData = res.List;
          that.paginations.page_size = res.PageSize;
          that.paginations.total = res.Total;
        } else {
          that.$message.error(res.Message);
        }
      });
    },
    // 分页
    handleCurrentChange(page) {
      this.paginations.page = page;
      this.searchEntity();
    },
    // 新增
    entityConfigClick() {
         this.modelFormDialog.EntityTwoID = ''
    this.modelFormDialog.QueryDataTwo = ''
    this.modelFormDialog.Remark = ''
      this.dialogVisible = true;
      this.title = "新增";
    },
    // 编辑
    entityConfigBian(row) {
    this.modelFormDialog.EntityTwoID = row.EntityID
    this.modelFormDialog.QueryDataTwo = row.ExpireDate
    this.modelFormDialog.Remark = row.Remark
      this.dialogVisible = true;
      this.title = "编辑";
    },
    // 点击新增
    dialogEntityConfig() {
      var that = this;
      if (that.title == "新增") {
        that.$refs.ref.validate((valid) => {
          if (!valid) return;
          var params = {
            EntityID: that.modelFormDialog.EntityTwoID,
            ExpireDate: that.modelFormDialog.QueryDataTwo,
            Remark: that.modelFormDialog.Remark,
          };
          API.getEntityConfigCreate(params).then((res) => {
            if (res.StateCode == 200) {
              that.$message.success("添加成功");
              that.dialogVisible = false;
              that.searchEntity();
            } else {
              that.$message.error(res.Message);
            }
          });
        });
      }else {
          that.$refs.ref.validate((valid) => {
          if (!valid) return;
          var params = {
            EntityID: that.modelFormDialog.EntityTwoID,
            ExpireDate: that.modelFormDialog.QueryDataTwo,
            Remark: that.modelFormDialog.Remark,
          };
          API.getEntityConfigUpload(params).then((res) => {
            if (res.StateCode == 200) {
              that.$message.success("编辑成功");
              that.dialogVisible = false;
              that.searchEntity();
            } else {
              that.$message.error(res.Message);
            }
          });
        });
      }
    },

    // 删除
    entityConfigDelete(row) {
        this.rowDelete = row
        this.dialogVisibleDelete = true
    },
    // 点击删除
    dialogEntityConfigDelete(){
        var that = this
        var params = {
            EntityID: that.rowDelete.EntityID
        }
        API.getEntityConfigDelete(params).then(res => {
            if(res.StateCode == 200){
                that.$message.success('删除成功')
                that.dialogVisibleDelete = false
                that.searchEntity()
            }else {
                that.$message.error(res.Message)
            }
        })
    },
  },
  mounted() {
    this.getStorageEntityNetwork();
    this.searchEntity();
  },
};
</script>

<style lang="scss">
</style>