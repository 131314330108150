/**
 * Created by preference on 2020/09/08
 *  zmx 
 */

import * as API from '@/api/index'
export default {
  /** 4.1.采购入库列表  */
  getpurchaseStorageList: params => {
    return API.POST('api/purchaseStorage/list', params)
  },
  /** 4.2.创建采购入库单  */
  getpurchaseStorageCreate: params => {
    return API.POST('api/purchaseStorage/create', params)
  },
  /**  4.3.采购入库单详情 */
  getpurchaseStorageInfo: params => {
    return API.POST('api/purchaseStorage/info', params)
  },
  /**  4.4.仓库列表 */
  getpurchaseStorageEntity: params => {
    return API.POST('api/purchaseStorage/entity', params)
  },
  /**  4.5.供应商列表 */
  getpurchaseStorageSupplier: params => {
    return API.POST('api/purchaseStorage/supplier', params)
  },
  /**  4.6.采购入库产品列表 */
  getpurchaseStorageProduct: params => {
    return API.POST('api/purchaseStorage/product', params)
  },
  /**  4.4.仓库列表 无权限限制 */
  getInventoryAllocationApplyOutEntity: params => {
    return API.POST('api/inventoryAllocationApply/outEntity', params)
  },
  // 打印小票内容
  print: params => {
    return API.POST('api/purchaseStorage/print', params)
  },
   /**  获取小票打印配置 */
   getReceiptConfigBill: params => {
    return API.POST('api/receiptConfig/bill', params)
  },

}